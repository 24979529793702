<script lang="ts">
    import { Drawer as DrawerPrimitive } from 'vaul-svelte';
    import { setContext } from 'svelte';

    type $$Props = DrawerPrimitive.Props;
    export let shouldScaleBackground: $$Props['shouldScaleBackground'] = true;
    export let open: $$Props['open'] = false;
    export let activeSnapPoint: $$Props['activeSnapPoint'] = undefined;

    setContext('drawer', {
        close: () => {
            open = false;
        },
        open: () => {
            open = true;
        },
    });
</script>

<DrawerPrimitive.Root {shouldScaleBackground} bind:open bind:activeSnapPoint direction="left" {...$$restProps}>
    <slot />
</DrawerPrimitive.Root>

<style>
    :global(.vaul-drawer-content) {
        position: fixed !important;
        left: 0 !important;
        right: auto !important;
        top: 0 !important;
        bottom: 0 !important;
        width: 300px !important;
        height: 100vh !important;
        max-height: 100vh !important;
        transform: translateX(-100%) !important;
        transition: transform 300ms ease !important;
    }

    :global(.vaul-drawer-content[data-state='open']) {
        transform: translateX(0) !important;
    }

    :global(.vaul-drawer-content[data-state='closed']) {
        transform: translateX(-100%) !important;
    }
</style>
